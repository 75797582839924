import axios from 'axios';

const apiKey = 'acc_72bb3e2c364edcb';
const apiSecret = 'a2807a6abbb8e9e6f9206fa2dbc2753b';

const ImaggaService = {
  getTags: async (imageUrl) => {
    const url = 'https://api.imagga.com/v2/tags?image_url=' + encodeURIComponent(imageUrl);
    const respImaga = await axios(url,   {
        auth: {
          username: apiKey,
          password: apiSecret,
        },
      });
    return respImaga.data.result.tags.slice(0,Math.floor(Math.random()* 15) + 5 );
  }
}

export default ImaggaService;