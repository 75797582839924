import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import Logo from "./admomentum_logo.png";
import DefaultImage from "./default-image.jpeg";
import AuthService from "../services/auth-facebook.service";
import AdsService from "../services/ads-facebook.service";

function Home() {
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [code, setCode] = useState("");
  const [accountData, setAccountData] = useState([]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const codeFromUrl = urlParams.get("code");

    setCode(codeFromUrl);
  }, []);

  const handleGetCampaigns = async () => {
    // Simular carga durante 2 segundos
    setLoading(true);
    setShowTable(false);
    const facebookToken = localStorage.getItem("token");
    if (!facebookToken) {
      const token = await AuthService.getTokenResponse(code);
      localStorage.setItem("token", token);
      console.log("FACEBOOK TOKEN RESPONSE --> ", token);
    }
    try {
      const adsData = await AdsService.getCampaignsData();
      console.log(adsData);
      setAccountData(adsData);
      setLoading(false);
      setShowTable(true);
    } catch (error) {
      console.error("Error al obtener los datos de la campaña:", error);
      setLoading(false);
    }
  };

  return (
    <div
      className="bg-image"
      style={{ backgroundImage: "url(/assets/loginPage.jpeg)" }}
    >
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div
          className="card w-95 h-95"
          style={{
            height: "95%",
            width: "95%",
            borderRadius: "15px",
            background: "rgba(255, 255, 255, 1)",
          }}
        >
          <div className="card-body overflow-auto ">
            <div className="sticky-top" >
              <img
                src={Logo}
                alt="Company Logo"
                style={{ width: "20%", marginBottom: "1rem" }}
              />
              <div className="mx-auto text-center">
                <button
                  className="btn btn-primary"
                  onClick={handleGetCampaigns}
                  disabled={loading}
                  style={{ width: "20%" }}
                >
                  {loading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading
                    </>
                  ) : (
                    "Get Campaigns"
                  )}
                </button>
              </div>
            </div>
            {!showTable ? (
              <></>
            ) : (
              accountData.map((account) => (
                <>
                  <h4 className="mt-5">Account Id:</h4>
                  <h5>{account.accountId}</h5>
                  {account.campaignDetails.length == 0 ? (
                    <span className="mx-auto text-center d-flex justify-content-center align-items-center">
                      There is no campaings
                    </span>
                  ) : (
                    <div className="table-responsive mt-3">
                      <table className="table table-bordered align-middle">
                        <thead className="table-dark">
                          <tr>
                            <th className="text-center field">Campaign Id</th>
                            <th className="text-center field">Status</th>
                            <th className="text-center">Image</th>
                            <th className="text-center desc-field">
                              Description
                            </th>
                            <th className="text-center field">Objective</th>
                            <th
                              className="text-center field"
                              style={{ minWidth: 80, maxWidth: 80 }}
                            >
                              Tags
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {account.campaignDetails.map((campaign) => (
                            <tr>
                              <td className="text-center field">
                                {campaign.id}
                              </td>
                              <td className="text-center field">
                                <span className={`badge ${campaign.status === 'ACTIVE' ? 'text-bg-success' : 'text-bg-secondary'}`}>
                                  {campaign.status}
                                </span>
                              </td>
                              <td className="text-center d-flex justify-content-center align-items-center">
                                <div
                                  className="d-flex justify-content-center align-items-center"
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <img
                                    src={
                                      campaign.adImage
                                        ? campaign.adImage.url
                                        : DefaultImage
                                    }
                                    style={{ "max-width": "100%" }}
                                  />
                                </div>
                              </td>
                              <td className="text-center desc-field">
                                {campaign.adNamePlatform}
                              </td>
                              <td className="text-center field">
                                {campaign.objective}
                              </td>
                              <td
                                className="text-center field"
                                style={{ minWidth: 80, maxWidth: 80 }}
                              >
                                {campaign.adTags ? (
                                  campaign.adTags.map((tag) => (
                                    <span className="badge rounded-pill text-bg-dark ms-2">
                                      {tag.tag.en}
                                    </span>
                                  ))
                                ) : (
                                  <span className="badge rounded-pill text-bg-dark ms-2">
                                    untagged
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
