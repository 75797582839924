import axios from 'axios';

const appId = '924145315646725';
const appSecret = 'dc9eefadec0f03a2a69197b78eccf254';
const redirectUri = `${process.env.REACT_APP_BASE_URL}/home`;

const AuthService = {

  getAuthUrl: () => {
    return `https://www.facebook.com/v19.0/dialog/oauth?client_id=${appId}&redirect_uri=${redirectUri}&scope=email,ads_management&response_type=code`;
  },
  getTokenResponse: async (code) => {
    try {
      const { data } = await axios.get(`https://graph.facebook.com/v19.0/oauth/access_token?client_id=${appId}&redirect_uri=${redirectUri}&client_secret=${appSecret}&code=${code}`);
      return data.access_token;
    } catch (error) {
      throw new Error(error); 
    }
  }

}

export default AuthService;