import axios from 'axios';
import ImaggaService from './api-imagga.service';
const adsSdk = require('facebook-nodejs-business-sdk');

const AdsService = {

  getCampaignsData: async() => {

    const response = [];

    const accessToken = localStorage.getItem('token');

    const api = adsSdk.FacebookAdsApi.init(accessToken);

    const responseAccounts = (await axios.get(`https://graph.facebook.com/v19.0/me/adaccounts?access_token=${accessToken}&fields=id`)).data.data;

    const adAccounts = responseAccounts;

    for(const adAccount of adAccounts){
     
      const accountId = adAccount.id;

      const responseData = {
        accountId,
        campaignDetails: []
      }

      let [responseCampaigns,responseImages] = await Promise.all([
        (await axios.get(`https://graph.facebook.com/v19.0/${accountId}/campaigns?access_token=${accessToken}&fields=name,id,status,objective`)),
        (await axios.get(`https://graph.facebook.com/v19.0/${accountId}/adimages?access_token=${accessToken}&fields=height,width,hash,url`))
      ]);

      responseCampaigns = responseCampaigns.data.data;
      responseImages = responseImages.data.data;

      for(const responseCampaign of responseCampaigns){
        const responseAd = (await axios.get(`https://graph.facebook.com/v19.0/${responseCampaign.id}/ads?access_token=${accessToken}&fields=name`)).data.data;
        const responseAdCreative = (await axios.get(`https://graph.facebook.com/v19.0/${responseAd[0].id}?access_token=${accessToken}&fields=source_ad,id,creative,adlabels`)).data;
        const responseAdCreativeData = (await axios.get(`https://graph.facebook.com/v19.0/${responseAdCreative.creative.id}?access_token=${accessToken}&fields=object_story_spec,categorization_criteria,category_media_source,adlabels,call_to_action_type,object_type,object_url,url_tags,image_url,image_hash,name,title,video_id,id,asset_feed_spec`)).data;
        
        let adImage;
        let adTags;

        if(responseAdCreativeData.asset_feed_spec && responseAdCreativeData.asset_feed_spec.images.length){
          adImage = responseImages.find((image)=> image.hash === responseAdCreativeData.asset_feed_spec.images[0].hash);
          adTags = await ImaggaService.getTags(adImage.url);
        }

        let campaignData = {
          id: responseCampaign.id,
          adNamePlatform: responseCampaign.name,
          adLabels: responseCampaign.adlabels,
          status: responseCampaign.status,
          objective: responseCampaign.objective,
          adImage,
          adTags
        }

        responseData.campaignDetails.push(campaignData);

      }
      response.push(responseData);

    }
    return response;
  }
}

export default AdsService;