// src/pages/LoginPage.js
import React from "react";
import { FaFacebook } from "react-icons/fa";
import AuthService from "../services/auth-facebook.service";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

function LoginPage() {

  const handleFacebookLink = async () => {
    const authUrl = await AuthService.getAuthUrl();
    window.open(authUrl)
  };

  return (
    <div
      className="bg-image"
      style={{ backgroundImage: "url(/assets/loginPage.jpeg)" }}
    >
      <div className="container d-flex justify-content-center align-items-center vh-100">
        <div className="card m-xl-5 p-2">
          <div className="card-body overflow-auto" style={{ width: 678, height:229.41 }}>
            <h1 className="card-title text-center mb-4">Welcome</h1>
            <div className="text-center">
              <h5 className="card-subtitle mb-4">
                This is a prototype for Admomentum, we built this app in order
                to show how we can link to Facebook, get all the campaigns, and
                set automatic tags.
              </h5>
            </div>
            <form>
              <div className="d-grid">
                <button type="submit" className="btn btn-primary" onClick={handleFacebookLink}>
                  <FaFacebook className="me-2" /> Link to Facebook
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
